/* Navbar.css */
.custom-navbar {
    background-color: rgba(0, 0, 0, 0.7) !important; /* Translucent black */
    padding-left: 50px;  /* Adjust values as needed */
    padding-right: 50px;
    /* width: 85%; */
    margin: auto;
  }
  
  .navbar-logo {
    height: 50px;  /* Adjust the height as needed */
    width: auto;  /* Maintain aspect ratio */
    fill: white; 
  }
  
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; 
  }
    
  .custom-navbar .navbar-collapse {
    justify-content: flex-end;
  }

  /* .nav-link:hover {
    color: red;
  } */

  .mid .nav-link:not(:last-child) {
    padding: 0 50px;
    border-right: 1px solid white; /* Adjust the color as needed */
  }

  .mid .nav-link:last-child {
    padding: 0 50px;
  }

  .custom-navbar .navbar-collapse {
    justify-content: center;
  }

/* responsiveness */
@media (max-width: 1024px) {
  .mid .nav-link:not(:last-child) {
    border-right: none;
  }

  .social-links {
    display: none;
  }
}


/* FOOTER */
.footer-wrapper {
  background-image: url('../assets/footer.png');
  background-size: cover;
  background-position: 0 -180px;
  background-repeat: no-repeat;
}

.clip-path-footer {
  clip-path: polygon(0 60%, 30% 0%, 100% 55%, 100% 100%, 0 100%);
  border-radius: 30px 30px 0 0;
}

.footer-no-bg {
  background-image: none !important;
}

@media (max-width: 1024px) {
  .footer-wrapper {
    background-position: 0 -100px;
  }
}

@media (max-width: 820px) {
  .clip-path-footer {
    clip-path: none;
    border-radius: 30px;
  }

  .footer-wrapper {
    background-image: none;
  }
  
}
