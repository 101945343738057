.fullscreen-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(60%); 
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
}

.about__blob path {
    stroke: red;
    fill: red;
}  

.about__blob2 path {
    stroke: red;
}  

.news {
    background-image: url("../assets/news_bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.button-bg{
    background-image: url("../assets/button-bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.home_bg2 {
    background-image: url("../assets/home_bg2.png");
    background-size: contain;
    background-repeat: no-repeat;
}
  
/* Add or update these styles in your existing CSS file */

.hexagon-container {
    position: relative;
    width: 120px; /* Adjust the size of the hexagon */
    height: 69.28px; /* Height is calculated for a regular hexagon */
    margin-bottom: 20px;
    perspective: 1000px; /* Perspective for 3D effect */
  }
  
  .hexagon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 150%;
    background-color: #e5e7eb; /* Light gray background */
    clip-path: polygon(
      50% 0%,
      100% 25%,
      100% 75%,
      50% 100%,
      0% 75%,
      0% 25%
    ); /* Hexagon shape */
  }
  
  .hexagon::before,
  .hexagon::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 150%;
    background-color: inherit;
    clip-path: inherit;
  }
  
  .hexagon::before {
    transform: rotateY(60deg); /* Position the left side */
  }
  
  .hexagon::after {
    transform: rotateY(-60deg); /* Position the right side */
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .fade-in {
    animation: fadeIn 2s ease-in forwards;
  }
    
  .custom-move-up {
    transform: translateY(-10px); /* Adjust this value as needed */
  }
    
  
/* ipad pro follows laptop size but a lot overlapping :") */
@media (max-width: 1402px) {
    .home_bg2 {
        background-size: contain;
        background-position: 0 40px;
        background-repeat: no-repeat;
    }
}

@media (max-width: 1024px) {
    .video-container {
        height: 50vh;
    }

    .overlay-text h1{
        font-size: 3.5rem;
        font-weight: bold;
    }

    .overlay-bee {
        bottom: -2%
    }

    .hex {
        width: 35%;
    }

    .split {
        flex-direction: column;
    }

    .ri-ght{
        text-align: center;
        justify-content: center;
    }
    .large-size, .large-size2{
        color: black;
    }

    .large-size{
        color: black;
        margin-left: 15%;
    }

    .hex-title {
        text-align: center;
        margin-top: 0;
        order: 2;
    }

    .hex-text {
        width: 100%;
        margin: 0;
    }

    .home_bg2 {
        background-image: none;
    }

    .ateam {
        width:70%;
        margin-left: 0;
        margin-bottom: 20px;
    }

}

@media (max-width: 820px) {
    .overlay-text h1{
        font-size: 2.5rem;
        font-weight: bold;
    }

    .hex {
        width: 30%;
    }
}

@media (max-width: 767px) {
    .overlay-text h1{
        font-size: 1.5rem;
    }

    .hex {
        width: 60%;
    }
}
@media (min-width: 1550px) {
    .home_bg2 {
        background-image: url("../assets/large_bg.png");
    }

    .large-size, .large-size2{
        color: black;
        font-size: 2rem;
    }
}