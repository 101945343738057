/* honeycomb */
.sol{
    background-image: url(../assets/honeycomb_bg.png);
    background-size: cover;
    background-position: center;
}

.logo {
  background-color: #FF3832;
}

.honeycomb {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
    align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  transform: translateY(80px);
}
.honeycomb-cell {
  -webkit-box-flex: 0;
          flex: 0 1 250px;
  max-width: 250px;
  height: 137.5px;
  margin: 65.4761904762px 12.5px 25px;
  position: relative;
  padding: 0.5em;
  text-align: center;
  z-index: 1;
  /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); */
}
.honeycomb-cell_title {
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  word-break: break-word;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  font-size: 1.75em;
  transform: rotate(30deg); /* Rotate the title by -45 degrees */
  transform-origin: top left; 
  -webkit-transition: opacity 350ms;
  transition: opacity 350ms;
}
.honeycomb-cell_title > small {
  font-weight: 300;
  margin-top: 0.25em;
}
.honeycomb-cell_image {
     object-fit: cover;
     object-position: center;
     /* filter: grayscale(100%); */
}

.honeycomb-cell::before,
 .honeycomb-cell::after {
  content: '';
}
.honeycomb-cell::before, 
.honeycomb-cell::after, 
.honeycomb-cell_image {
  top: -50%;
  left: 0;
  width: 100%;
  height: 200%;
  display: block;
  position: absolute;
  -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
          clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  z-index: -1;
}
.honeycomb-cell_image2 {
  top: -30%;
  left: 20;
  display: block;
  position: absolute;
  z-index: -1;
}

.honeycomb-cell::before {
  /* background: #fff; */
  -webkit-transform: scale(1.055);
          transform: scale(1.055);
}

.honeycomb-cell::after {
  background: #111111;
  opacity: 0;
  -webkit-transition: opacity 350ms;
  transition: opacity 350ms;
}
.honeycomb-cell:hover 
.honeycomb-cell_title {
  opacity: 0;
}
.honeycomb-cell:hover 
.honeycomb-cell_image
{
    filter: grayscale(0%);
}
.honeycomb-cell:not(:first-child):not(:last-child):hover::before {
  background: #fc5130;
}
.honeycomb-cell:hover::after {
  opacity: 0;
}
.honeycomb_placeholder {
  display: none;
  opacity: 0;
  width: 250px;
  margin: 0 12.5px;
}

.quote {
  border: 2px solid red;
  padding: 20px;
  width: 100%;
  margin-left: 0
}

@media (min-width: 350px) and (max-width: 550px) 
{
  .honeycomb-cell
  {
    margin: 30px 10px;
    max-width: 140px;
    height: 80.5px; 
  }
  .honeycomb-cell:first-child, .honeycomb-cell:last-child {
    display: none;
  }

  .honeycomb-cell:nth-child(n+2):not(:last-child) {
    order: -1;
  }

  .honeycomb-cell:nth-child(3n + 1):not(:last-child) {
    margin-right: calc(50% - 125px);
    margin-left: calc(50% - 125px);
  }
  
}

@media (min-width: 550px) and (max-width: 825px) {
    .honeycomb-cell:first-child, .honeycomb-cell:last-child {
      display: none;
    }
  
    .honeycomb-cell:nth-child(n+2):not(:last-child) {
      order: -1;
    }
  
    .honeycomb-cell:nth-child(3n + 1):not(:last-child) {
      margin-right: calc(50% - 125px);
      margin-left: calc(50% - 125px);
    }
  }
  
@media (min-width: 825px) and (max-width: 1100px) {
  .honeycomb-cell:nth-child(5n + 4) 
  {
    margin-left: calc(50% - 275px);
  }

  .honeycomb-cell:nth-child(5n + 5) 
  {
    margin-right: calc(50% - 275px);
  }

  .honeycomb_placeholder:nth-child(5n), 
  .honeycomb_placeholder:nth-child(5n + 3) 
  {
    display: block;
  }
  .honeycomb-cell:last-child {
    display: none;
  }
}
@media (min-width: 1100px) 
{
  .honeycomb-cell:nth-child(7n + 5) {
    margin-left: calc(50% - 400px);
  }

  .honeycomb-cell:nth-child(7n + 7), 
  .honeycomb-cell:nth-child(7n + 5):nth-last-child(2) 
  {
    margin-right: calc(50% - 400px);
  }

  .honeycomb_placeholder:nth-child(7n + 7), 
  .honeycomb_placeholder:nth-child(7n + 9), 
  .honeycomb_placeholder:nth-child(7n + 11) 
  {
    display: block;
  }
  .honeycomb-cell:first-child .honeycomb-cell_image2 {
    display: none;
  }

  .honeycomb-cell:first-child {
    background: none;
    background-color: transparent;
  }
}

@media (max-width: 345px) {
  .honeycomb-cell
  {
    margin: 30px;
    max-width: 120px;
    height: 60px; 
  }
  .honeycomb-cell:last-child .honeycomb-cell_image2 {
    display: none;
  }

}