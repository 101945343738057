.testimonial {
    clip-path: polygon(0 0, 75% 0, 100% 70%, 100% 100%, 0 100%); 
}

.testimonials {
    padding-bottom: 80px;
    background-image: url(../assets/testimonial_bg.png);
    background-size: cover;
    background-position: top;
}

.img-bg1 {
    background-image: url("../assets/txt_bg.png"); 
    background-size: cover;
    background-repeat: no-repeat;  
    background-position: 0 150px;
}

.img-bg1 h2 {
    padding-bottom: 50px;
}

.proj_bg {
    background-image: url("../assets/projbg.png");
    background-size: cover;
    background-repeat: no-repeat;  
    background-position: top;
}

.slides-container::-webkit-scrollbar {
    display: none;
  }
  
  .slides-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  

@media screen and (max-width: 1024px) {
    .team-more {
        padding: 5px;
        margin-top: 10px;
    }
    .img-bg1 {
        background-image: none;
    }
    .img-bg1 h2 {
        padding-bottom: 0px;
    }
    
}

@media (min-width: 1550px) {
    .img-bg1 {
        background-position: 0 -10px;
    }
}